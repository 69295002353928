import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import UserFeedback from "../../components/userFeedback/userFeedback";
import Loader from "../../components/Loader/Loader";
import { setLoading } from "../../RtkStore/slices/loaderSlice";
import AuthServices from "../../services/SignUpApi";
import "./ResetPassword.css";

const ResetPassword = () => {
  const { tokenPath } = useParams();
  const loading = useSelector((state) => state.loader.loading);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  // State for toggling password visibility
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const toggleConfirmPasswordVisibility = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.password) {
      newErrors.password = "Password is required";
    } else if (formData.password.length < 6) {
      newErrors.password = "Password must be at least 6 characters";
    }

    if (!formData.confirmPassword) {
      newErrors.confirmPassword = "Confirm Password is required";
    } else if (formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(setLoading(true));

    try {
      if (validateForm()) {
        const payload = { ...formData, token: tokenPath };

        const response = await AuthServices.resetPassword(payload);

        if (response.status === 200) {
          setMessage("Your password has been reset successfully.");
          dispatch(setLoading(false));

          setTimeout(() => {
            navigate("/login");
          }, 3000);
        }
      }
    } catch (error) {
      dispatch(setLoading(false));
      setError(error.response?.data || "An error occurred. Please try again.");
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <>
      {loading && <Loader />}

      <div className="rp-wrapper">
        <div className="rp-form-container">
          {/* Glow Effect */}
          <div className="glow"></div>

          {/* Left Side */}
          <div className="formleft-side">
            <UserFeedback
              para1={
                "Set a new password to regain access to your account securely."
              }
              para2={"Make sure your new password is strong and memorable."}
            />
          </div>

          {/* Right Side */}
          <div className="formright-side">
            <h2>Reset Password</h2>
            <form onSubmit={handleSubmit} noValidate>
              <div className="input-container">
                <div className="form__group field">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form__field"
                    placeholder="Password"
                    name="password"
                    id="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    required
                  />
                  <label htmlFor="password" className="form__label">
                    New Password
                  </label>
                  <span className="eye-icon" onClick={togglePasswordVisibility}>
                    {showPassword ? (
                      <VisibilityOffOutlinedIcon />
                    ) : (
                      <VisibilityOutlinedIcon />
                    )}
                  </span>
                  {errors.password && (
                    <span className="error-message">{errors.password}</span>
                  )}
                </div>
              </div>

              <div className="input-container">
                <div className="form__group field">
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    className="form__field"
                    placeholder="Confirm Password"
                    name="confirmPassword"
                    id="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={handleInputChange}
                    required
                  />
                  <label htmlFor="confirmPassword" className="form__label">
                    Confirm Password
                  </label>
                  <span
                    className="eye-icon"
                    onClick={toggleConfirmPasswordVisibility}
                  >
                    {showConfirmPassword ? (
                      <VisibilityOffOutlinedIcon />
                    ) : (
                      <VisibilityOutlinedIcon />
                    )}
                  </span>
                  {errors.confirmPassword && (
                    <span className="error-message">
                      {errors.confirmPassword}
                    </span>
                  )}
                </div>
              </div>

              <button type="submit" className="submit-button" disabled={loading}>
                {loading ? "Resetting Password..." : "Reset Password"}
              </button>
            </form>

            {message && <p className="success-message">{message}</p>}
            {error && <p className="error-message">{error}</p>}

            <p className="rp-sign-in-btn">
              Back to <br />
              <Link to="/login">Login</Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
