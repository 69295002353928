import React from "react";
import "./contact.scss";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import { Facebook, Twitter, Instagram } from "@material-ui/icons";
import { useState, useEffect } from "react";
import axios from "axios";
import { Avatar, Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Logo from "../../components/Logo/Logo";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "white",
  border: "none",
  boxShadow: 24,
  p: 4,
  display: "flex",
  alignItems: "center",
  borderRadius: "12px",
  textAlign: "center",
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "stretch",
    gap: "30px",
    padding: "40px",
    maxWidth: "1200px",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      padding: "20px",
    },
  },
  leftContainer: {
    flex: 1,
    background: "#ffffff",
    padding: "40px",
    borderRadius: "12px",
    boxShadow: "0 10px 30px rgba(0, 0, 0, 0.1)",
  },
  rightContainer: {
    flex: 1,
    textAlign: "center",
    background: "#ffffff",
    padding: "40px",
    borderRadius: "12px",
    fontSize: "18px",
    boxShadow: "0 10px 30px rgba(0, 0, 0, 0.1)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  mapContainer: {
    marginTop: "30px",
    width: "100%",
    height: "300px",
    borderRadius: "12px",
    overflow: "hidden",
    boxShadow: "0 5px 15px rgba(0, 0, 0, 0.1)",
  },
  iconButton: {
    transition: "all 0.3s ease",
    margin: "0 10px",
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    "&:hover": {
      transform: "translateY(-3px)",
      backgroundColor: "rgba(90, 79, 207, 0.1)",
    },
  },
  facebookIcon: {
    color: "#3b5998",
    fontSize: 30,
  },
  twitterIcon: {
    color: "#00acee",
    fontSize: 30,
  },
  instagramIcon: {
    color: "#C13584",
    fontSize: 30,
  },
  contactHeader: {
    color: "#5a4fcf",
    marginBottom: "30px",
    fontSize: "2rem",
    fontWeight: "700",
  },
  contactInfo: {
    marginBottom: "30px",
    "& p": {
      margin: "15px 0",
      fontSize: "1.1rem",
    },
  },
}));

function ContactUs() {
  const classes = useStyles();
  const [input, setInputs] = useState({ name: "", email: "", phone: "", message: "" });
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);

  const handleInput = (e) => {
    setInputs({ ...input, [e.target.name]: e.target.value });
  };

  const postContactData = async () => {
    const { name, email, phone, message } = input;
    await axios
      .post(
        "https://an2sldwgo0.execute-api.ap-south-1.amazonaws.com/stage1/public/user/contact",
        { name, email, phone, message }
      )
      .then((res) => {
        if (res.status === 200) {
          setOpen(true);
        }
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { name, email, phone, message } = input;
    let isFormValid = true;
    let errorMessage = "";

    if (!name || !email || !phone || !message) {
      isFormValid = false;
      errorMessage = "Please fill all the required fields";
    }

    if (email && !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
      isFormValid = false;
      errorMessage = "Please enter a valid email address";
    }

    if (!isFormValid) {
      alert(errorMessage);
      return;
    }

    postContactData();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="contact-page">
      <div className="header_section">
        <h2>We're here to help</h2>
        <p>Have questions about Katalisia? Reach out and we'll respond promptly.</p>
      </div>
      
      <div className={classes.container}>
        <div className={classes.leftContainer}>
          <form onSubmit={handleSubmit}>
            <h2 className={classes.contactHeader}>Send Us a Message</h2>
            <div className="form-group">
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                className="input"
                id="name"
                name="name"
                placeholder="Enter your name"
                onChange={handleInput}
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                className="input"
                placeholder="Enter your email"
                onChange={handleInput}
              />
            </div>
            <div className="form-group">
              <label htmlFor="phone">Phone:</label>
              <input
                type="text"
                id="phone"
                name="phone"
                className="input"
                placeholder="Enter your phone number"
                onChange={handleInput}
              />
            </div>
            <div className="form-group">
              <label htmlFor="message">Message:</label>
              <textarea
                id="message"
                className="textarea"
                name="message"
                placeholder="Enter your message"
                onChange={handleInput}
              ></textarea>
            </div>
            <button className="buttonSubmit" type="submit">
              Send Message
            </button>
          </form>
        </div>
        
        <div className={classes.rightContainer}>
          <h2 className={classes.contactHeader}>Contact Information</h2>
          <div className={classes.contactInfo}>
          <p><strong>Katalisia</strong> </p>
             
            <p><strong>Email:</strong> support@xyz.com</p>
          </div>
          
          <div>
            <IconButton className={classes.iconButton} aria-label="Facebook">
              <Facebook className={classes.facebookIcon} />
            </IconButton>
            <IconButton className={classes.iconButton} aria-label="Twitter">
              <Twitter className={classes.twitterIcon} />
            </IconButton>
            <IconButton className={classes.iconButton} aria-label="Instagram">
              <Instagram className={classes.instagramIcon} />
            </IconButton>
          </div>
          
          <div className={classes.mapContainer}>
            <iframe
              width="100%"
              height="100%"
              frameBorder="0"
              scrolling="no"
              marginHeight="0"
              marginWidth="0"
              src="https://maps.google.com/maps?q=New%20Delhi&t=&z=13&ie=UTF8&iwloc=&output=embed"
              title="Our Location"
            ></iframe>
          </div>
        </div>
      </div>
      
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Avatar sx={{ bgcolor: '#5a4fcf', margin: '0 auto 20px', width: 60, height: 60 }}>
            ✓
          </Avatar>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Message Sent Successfully!
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}

export default ContactUs;