import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Pricing.scss";

const Pricing = () => {
  const [activePlan, setActivePlan] = useState("brands");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="pricing-container">
      <div className="hero-content">
      <h1 className="pricing-title">
        Transparent Pricing. Powerful Value. Tailored For You
      </h1>
      <p className="pricing-subtitle">
        Whether You're a Creator or a Company — Grow Smarter with Katalisia
      </p>
      <p className="pricing-subtitle">
        Explore flexible plans designed for every journey: From solo creators to
        global marketing teams.
      </p>
      </div>
      <div className="toggle-buttons">
        <button
          className={`toggle-btn ${activePlan === "brands" ? "active" : ""}`}
          onClick={() => setActivePlan("brands")}
        >
          For Creator
        </button>
        <button
          className={`toggle-btn ${activePlan === "creators" ? "active" : ""}`}
          onClick={() => setActivePlan("creators")}
        >
          For Company
        </button>
        <button
          className={`toggle-btn ${
            activePlan === "enterprise" ? "active" : ""
          }`}
          onClick={() => setActivePlan("enterprise")}
        >
          Enterprise / Agencies
        </button>
      </div>

      {/* Brands & Agencies Plans */}
      {activePlan === "brands" && (
        <>
          <h2 className="section-title">For Creator</h2>
          <div className="pricing-plans">
            {/* Starter Plan */}
            <div className="plan-card">
              <h3>Starter</h3>
              <p className="price">INR 0/month</p>
              <ul>
                <li>✔ Connect YouTube</li>
                {/* <li>✔ Weekly Recommendations</li> */}
                <li>✔ Basic Analytics Dashboard</li>
                <li>✔ Limited Ad Campaign Invites (2/month)</li>
              </ul>
              <br></br>
              <br></br>
              <br></br>
           
              
              
              
             
              <Link to="/contact" className="btn">Contact Us</Link>
            </div>

            {/* Standard Plan */}
            <div className="plan-card popular">
              <h3>Premium</h3>
              <p className="price">INR 2,000/month</p>
              <ul>
                <li>✔ Unlimited Ad Campaign Invites</li>
                <li>✔ Intelligent Recommendations</li>
                <li>✔ Content Scheduling Tools</li>
                <li>✔ Advanced Analytics</li>
                {/* <li>✔ Priority Support</li> */}
                <br></br>
                <br></br>
                {/* <li class="highlight">
                  💡 Save 20% on Annual Billing (INR 4,800/year)
                </li> */}
              </ul>
             
              <Link to="/contact" className="btn">Contact Us</Link>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              
{/* 
              <p class="highlight">“Unlock your full potential. With Premium, you don’t just grow —
                you thrive with data-driven decisions.”</p> */}
             
                
             
            </div>
          </div>

     
        
        </>
      )}

      {/* Creator Plan */}
      {activePlan === "creators" && (
        <>
          <h2 className="section-title">For Companies</h2>
          <div className="pricing-plans">
            <div className="plan-card creator-plan">
              <h3>Standard</h3>
              <p className="price">INR 5,000/month</p>
              <p class="highlight">15% of Campaign Budget</p>
              <br></br>
              <br></br>
              <ul>
                <li>✔ Create & Manage Ad Campaigns </li>
                <li>✔ AI-based Creator Shortlisting</li>
                <li>✔ Smart Collaboration & Invite System</li>
                <li>✔ Campaign Performance Analytics & Recommendations</li>
                <br></br>
                <br></br>
                
                
              </ul>
              <Link to="/contact" className="btn">Contact Us</Link>
            </div>

            

            
          </div>
        </>
      )}

      {/* enterprise Plan */}
      {activePlan === "enterprise" && (
        <>
          <h2 className="section-title">For Digital Agencies & Enterprises</h2>
          <div className="pricing-plans">
            <div className="plan-card creator-plan">
              <h3>Partner with Katalisia for Scale</h3>
               <p className="price">Custom Pricing </p> 
               <p class="highlight">(Contact Us) </p>

               
               <br></br>
               <br></br>
              <ul>
                <li>✔ Onboarding for 50+ creators </li>
                <li>✔ Multi-tenancy Dashboard</li>
                <li>✔ Whitelabel Support</li>
                <li>✔ Campaign Management Tools</li>
                <li>✔ Creator Matching & Analytics</li>
                <li>✔ API Access & Lead Generation Tools</li>
              </ul>
              
              <Link to="/contact" className="btn">Contact Us</Link>
            </div>
          </div>
        </>
      )}

     
     
    </div>
   
  );
};

export default Pricing;
