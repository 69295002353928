import React from "react";
import Logo from "../Logo/Logo";
import { Link, useLocation } from "react-router-dom";
import { Instagram, Twitter, LinkedIn, YouTube } from "@mui/icons-material"; // MUI Icons
import "./Footer.scss"; // Import CSS

const Footer = () => {
  const location = useLocation();

  if (["/signup", "/privacy-policy"].includes(location.pathname)) return null;

  return (
    <div className="footer-container">
      <div className="footer-logo">
        <Logo />
      </div>

      <nav className="footer-links-section">
      <Link to={"/about"} className="footer-links" >
          About Us
        </Link> 
        <Link to={"/contact"} className="footer-links" >
          Contact Us
        </Link>
        
        <Link to={"/privacy-policy"} className="footer-links">
          Privacy Policy
        </Link>
        
      </nav>

      <div className="social-icons">
        <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="social-icon instagram-icon">
          <Instagram fontSize="small" />
        </a>
        <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" className="social-icon linkedin-icon">
          <LinkedIn fontSize="small" />
        </a>
        <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="social-icon twitter-icon">
          <Twitter fontSize="small" />
        </a>
        <a href="https://youtube.com" target="_blank" rel="noopener noreferrer" className="social-icon youtube-icon">
          <YouTube fontSize="small" />
        </a>
      </div>
    </div>
  );
};

export default Footer;
