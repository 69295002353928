import React, { useEffect } from "react";
import "./about.scss";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main className="about-page">
      {/* Hero Section */}
      <section className="about-hero">
        <div className="hero-content">
          <h1>Unlock Your Social Media Potential</h1>
          <p className="hero-subtitle">
            Advanced analytics for content creators and businesses
          </p>
        </div>
      </section>

      {/* About Section */}
      <section className="about-section">
        <div className="container about-container">
          <div className="about-image-wrapper">
            <img
              src="https://images.pexels.com/photos/3183150/pexels-photo-3183150.jpeg"
              alt="Team analyzing data"
              className="about-main-image"
            />
            <div className="image-decoration"></div>
          </div>
          <div className="about-content">
            <h2 className="section-title">
              About <span className="highlight">Katalisia</span>
            </h2>
            <div className="about-text">
              <p>
                At Katalisia, we're passionate about helping content creators, companies, 
                and social media marketing agencies unlock the full potential of social media. 
                We believe that with the right tools and insights, anyone can succeed on 
                platforms like YouTube, Instagram, Facebook and beyond.
              </p>
              
              <p>
                Our team of experienced developers and data analysts has worked tirelessly 
                to create a software solution that provides deep insights into social media 
                engagement and monetization. Our platform is designed to be easy to use yet 
                powerful, allowing users to quickly analyze their data and make informed decisions.
              </p>
              
              <p>
                But we're not just about technology. We're also committed to providing 
                top-notch support and guidance to our users. Whether you're just starting 
                out or have been in the social media game for a while, we're here to help 
                you achieve your goals.
              </p>
              
              <p>
                So if you're looking to take your social media presence to the next level, 
                give Katalisia a try. We're confident that our platform will help you unlock 
                new opportunities and reach new heights of success.
              </p>
            </div>
            
            <div className="cta-container">
              <Button
                variant="contained"
                color="primary"
                size="large"
                className="cta-button"
                component={Link}
                to="/signup"
              >
              SIGN-UP
              </Button>
              <Button
                variant="outlined"
                color="primary"
                size="large"
                className="secondary-button"
                component={Link}
                to="/contact"
              >
                Contact Our Team
              </Button>
            </div>
          </div>
        </div>
      </section>




      {/* Vision Section */}
      <section className="vision-section">
        <div className="container vision-container">
          <div className="vision-content">
            <h2 className="section-title">
              Our <span className="highlight">Vision</span>
            </h2>
            <div className="vision-text">
              <p>
                Our vision is to become the go-to platform for social media analytics and 
                engagement tracking. By leveraging the power of data and insights, we help 
                content creators and companies around the world increase their visibility, 
                reach new audiences, and drive growth.
              </p>
              <p>
                At Katalisia, we believe that our success is measured by the success of our 
                users. We are committed to providing a platform that is easy to use, accurate, 
                and delivers real value to our customers.
              </p>
              <p>
                Join us on our mission to empower content creators and businesses to reach 
                their full potential through social media.
              </p>
            </div>
          </div>
          <div className="vision-image-wrapper">
            <img
              src="https://images.pexels.com/photos/3183153/pexels-photo-3183153.jpeg"
              alt="Vision illustration"
              className="vision-main-image"
            />
            <div className="image-decoration vision-decoration"></div>
          </div>
        </div>
      </section>

      {/* Value Proposition Section */}
      <section className="value-props">
        <div className="container">
          <h2 className="section-title centered">Why Choose Katalisia</h2>
          <div className="props-grid">
            <div className="prop-card">
              <div className="prop-icon">📊</div>
              <h3>Deep Analytics</h3>
              <p>
                Uncover insights that matter with our comprehensive social media metrics
                and performance tracking.
              </p>
            </div>
            <div className="prop-card">
              <div className="prop-icon">⚡</div>
              <h3>Powerful Tools</h3>
              <p>
                Our intuitive platform makes complex data analysis simple and actionable.
              </p>
            </div>
            <div className="prop-card">
              <div className="prop-icon">🤝</div>
              <h3>Expert Support</h3>
              <p>
                Get guidance from our team of social media specialists whenever you need it.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Team Section */}
      
    </main>
  );
}

export default About;