import React, { useState, useEffect, useCallback } from "react";
import "./ScrollToTop.scss";

const ScrollToTop = () => {
    const [isVisible, setIsVisible] = useState(false);

    // Debugging: Log component render
    console.log("ScrollToTop component rendered");

    // Optimized scroll event handling
    const toggleVisibility = useCallback(() => {
        console.log("Scroll event triggered, scrollY:", window.scrollY);
        setIsVisible(window.scrollY > 300);
    }, []);

    useEffect(() => {
        // Debugging: Log event listener attachment
        console.log("Adding scroll event listener");
        window.addEventListener("scroll", toggleVisibility);
        return () => {
            console.log("Removing scroll event listener");
            window.removeEventListener("scroll", toggleVisibility);
        };
    }, [toggleVisibility]);

    // Debugging: Log isVisible state
    console.log("isVisible:", isVisible);

    // Scroll to top function
    const scrollToTop = () => {
        console.log("Scrolling to top");
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    return (
        <div className="scrollToTopContainer">
            <button
                onClick={scrollToTop}
                className={`scroll-to-top ${isVisible ? "show" : ""}`}
                aria-label="Scroll to top"
            >
                &#8679; {/* Up arrow icon */}
            </button>
        </div>
    );
};

export default ScrollToTop;