import React, { useState } from "react";
import Logo from "../Logo/Logo";
import { Link, useLocation } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const Navbar = () => {
  const [className, setClassName] = useState(true);
  const [productDropdownOpen, setProductDropdownOpen] = useState(false);
  const [servicesDropdownOpen, setServicesDropdownOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();

  if (location.pathname === "/signup" || location.pathname === "/privacy-policy") {
    return null;
  }

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const toggleProductDropdown = () => {
    setProductDropdownOpen(!productDropdownOpen);
    setServicesDropdownOpen(false); // Close other dropdown
  };

  const toggleServicesDropdown = () => {
    setServicesDropdownOpen(!servicesDropdownOpen);
    setProductDropdownOpen(false); // Close other dropdown
  };

  // Toggle the hamburger menu and side menu
  const sidePopup = () => {
    setClassName(!className);
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="navbar-main-Container">
      <div className="navbar-content">
        <div className="navbar-logo">
          <Logo />
        </div>

        <div className="navbar-nav">
          <div className="nav-item">
            <Link to="/" className="nav-link" aria-current="page">
              Home
            </Link>
          </div>

          {/* Product Dropdown */}
          <div
            className="nav-item dropdown"
            onMouseEnter={() => setProductDropdownOpen(true)}
            onMouseLeave={() => setProductDropdownOpen(false)}
          >
            <button className="dropbtn">
              Product {productDropdownOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </button>
            <div className={`dropdown-content ${productDropdownOpen ? "open" : ""}`}>
              {/* <Link to="/product" className="dropdown-link">
                Ad Suite
              </Link>
              <Link to="/product" className="dropdown-link">
                Creator Suite
              </Link> */}
              <span className="dropdown-link ">
              Ad Suite
              </span>
              <span className="dropdown-link ">
              Creator Suite
              </span>
            </div>
          </div>

          {/* Services Dropdown */}
          <div
            className="nav-item dropdown"
            onMouseEnter={() => setServicesDropdownOpen(true)}
            onMouseLeave={() => setServicesDropdownOpen(false)}
          >
            <button className="dropbtn">
              Services {servicesDropdownOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </button>
            <div className={`dropdown-content ${servicesDropdownOpen ? "open" : ""}`}>
              {/* <Link to="/service" className="dropdown-link">
                Social Media Management
              </Link>
              <Link to="/service" className="dropdown-link">
                Ad Campaign Strategy & Execution
              </Link> */}
              <span className="dropdown-link ">
                Social Media Management
              </span>
              <span className="dropdown-link ">
                Ad Campaign Strategy & Execution
              </span>
            </div>
          </div>


          <div className="nav-item">
            <Link to="/pricing" className="nav-link">
              Pricing
            </Link>
          </div>
          <div className="nav-item">
            <Link to="/about" className="nav-link">
              About
            </Link>
          </div>

          <div className="nav-item">
            <Link to="/contact" className="nav-link">
              Contact
            </Link>
          </div>
        </div>
      </div>


      <div className={` ${className ? "navbar-options" : "navbar-options-for-mobile-view"}`}>
        <div className="navbar-account-section">
          <div className="nav-item">
            <Link to="/signup">
              <button className="navbar-button navbar-signup-button">Create a Free Account</button>
            </Link>
          </div>
          <div className="nav-item">
            <Link to="/login">
              <button className="navbar-button navbar-login-button">Login</button>
            </Link>
          </div>
        </div>
      </div>

      {/* Mobile screen */}
      <div onClick={sidePopup} className={`navbar-content-for-mobile-view ${className ? "" : "after-click"}`}>
        <div></div>
        <div></div>
        <div></div>
      </div>

      {/* Slide Menu */}
      <div className={`mobile-slide-menu ${menuOpen ? "slide-in" : "slide-out"}`}>
        <div className="mobile-menu-header">
          <div className="mobile-logo">
            <Logo />
          </div>

          <button className="close-menu-button" onClick={toggleMenu}>
            {menuOpen ? "✕" : "☰"}
          </button>
        </div>

        <div className="mobile-nav-item">
          <Link to="/" className="nav-link" onClick={sidePopup}>
            Home
          </Link>
        </div>
        <div className="mobile-nav-item">
          <span className="nav-link text-gray-400">
            Product
          </span>
          {/* <Link to="/product" className="nav-link" onClick={sidePopup}>
            Product
          </Link> */}
        </div>
        <div className="mobile-nav-item">
          <span className="nav-link text-gray-400 ">
            Services
          </span>
          {/* <Link to="/services" className="nav-link" onClick={sidePopup}>
            Services
          </Link> */}
        </div>
        <div className="mobile-nav-item">
          <Link to="/about" className="nav-link" onClick={sidePopup}>
            About Us
          </Link>
        </div>
        <div className="mobile-nav-item">
          <Link to="/contact" className="nav-link" onClick={sidePopup}>
            Contact
          </Link>
        </div>

        <div className="mobile-account-section">
          <Link to="/signup">
            <button className="mobile-signup-button">Sign Up</button>
          </Link>
          <Link to="/login">
            <button className="mobile-login-button">Login</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
