import { useEffect } from "react";
import { Link } from "react-router-dom";

export const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="privacy-policy-container">
      <div className="privacy-policy">
        <h2>Privacy Policy</h2>
        <p>
          <strong>Effective Date:</strong> 31-March-2025
        </p>
        <br></br>
        <br></br>
        <h3>1.Introduction</h3>
        <p>
          Welcome to Katalisia. Your privacy is important to us. This Privacy
          Policy explains how we collect, use, disclose, and protect your
          personal information when you use our services. Katalisia is a
          technology-driven platform that enables companies and content creators
          to collaborate on influencer marketing campaigns, access analytics,
          and optimize their social media presence. We are committed to
          protecting your data and providing a transparent overview of how your
          information is handled.
        </p>
        <br></br>
        <br></br>
        <h3>2.Who We Are</h3>
        <p>
          Katalisia is operated by katalisia LLP a India-based software company.
          Our platform offers services to both:{" "}
        </p>
        <ul>
          <li>
            <strong>Content Creators:</strong>who connect their YouTube accounts
            to access personalized analytics and campaign opportunities.
          </li>
          <li>
            <strong>Companies:</strong> who launch ad campaigns and interact
            with creators through our platform.
          </li>
        </ul>
        <br></br>
        <br></br>
        <h3>3.Information We Collect</h3>
        <p>We collect and process the following types of data: </p>
        <h4>a.Information You Provide </h4>
        <ul>
          <li>Name, email address, password (hashed) </li>
          <li>Company profile details (for brand accounts) </li>
          <li>
            Payment and billing information (processed through secure
            third-party gateways like Stripe or PayPal)
          </li>
          <li>Any communications sent to support</li>
        </ul>

        <br></br>
        <br></br>
        <h3>YouTube Integration via Google OAuth 2.0:</h3>
        <p>We request the following Google scopes during authentication: </p>
        <p>Non-Sensitive Scopes: </p>
        <ul>
          <li>
            <strong>.../auth/youtube.download:</strong>Download your public
            YouTube videos{" "}
          </li>
          <li>
            <strong>.../auth/yt-analytics.readonly:</strong> View YouTube
            Analytics reports for your YouTube content{" "}
          </li>
        </ul>
        <br></br>
        <br></br>
        <h3>Sensitive Scopes (with explicit user consent): </h3>
        <ul>
          <li>
            <strong>.../auth/youtube.readonly:</strong>View your YouTube account
          </li>
          <li>
            <strong>.../auth/youtubepartner-channel-audit:</strong>View private
            info for audit{" "}
          </li>
          <li>
            <strong>.../auth/youtube.channel-memberships.creator:</strong> View
            channel membership info{" "}
          </li>
          <li>
            <strong>.../auth/yt-analytics-monetary.readonly: </strong>View
            YouTube monetary analytics{" "}
          </li>
          <li>
            <strong>.../auth/youtubepartner:</strong> View/manage assets on
            YouTube
          </li>
        </ul>
        <br></br>
        <br></br>

        <h3>We use these scopes only to:</h3>
        <ul>
          <li>Analyze video performance and viewer engagement </li>
          <li>Generate custom audience insights </li>
          <li>Display relevant analytics in your Katalisia dashboard </li>
          <li>Match creators with ad campaigns </li>
          <p>
            You can revoke access at any time via your Google Account
            permissions.{" "}
          </p>
        </ul>

        <br></br>
        <br></br>

        <h3>4. How We Use Your Information</h3>
        <p>We use your data to: </p>
        <ul>
          <li>Deliver platform features and insights </li>
          <li> Provide personalized analytics and recommendations </li>
          <li>Match content creators with relevant ad campaigns </li>
          <li>
            Communicate updates, newsletters, or service-related messages{" "}
          </li>
          <li>Ensure platform security and performance </li>
        </ul>

        <br></br>
        <br></br>

        <h3> 5. Data Sharing & Disclosure</h3>
        <p>
          We do <strong>not</strong> sell your personal data. We may share data
          only with:{" "}
        </p>
        <ul>
          <li>
            Trusted third parties (payment processors, analytics providers){" "}
          </li>
          <li>Legal authorities, if required under applicable law </li>
          <li>
            Brands/Companies, only aggregated, anonymized influencer data for
            campaign decision-making{" "}
          </li>
        </ul>

        <br></br>
        <br></br>

        <h3>6.Data Retention</h3>
        <ul>
          <li>We retain your data for as long as your account is active. </li>
          <li>
            You may delete your account anytime, and we will permanently delete
            all associated data within 30 days.{" "}
          </li>
        </ul>

        <br></br>
        <br></br>

        <h3>7.Data Security</h3>
        <p>
          We use advanced encryption, secure OAuth 2.0 protocols, access tokens,
          and role-based access controls to protect your data
        </p>
        <p>
          All API interactions and stored data are encrypted using
          industry-standard protocols such as HTTPS, SHA-256, and AES-256.{" "}
        </p>

        <br></br>
        <br></br>

        <h3>8.Your Rights</h3>
        <p>Under GDPR and other data protection laws, you may: </p>
        <ul>
          <li>Access your personal data </li>
          <li>Correct inaccurate data </li>
          <li>Request deletion of your data </li>
          <li>Object to data processing </li>
          <li>Export your data in a machine-readable format </li>
          <p>
            To exercise your rights, please contact:
            <strong>[privacy@katalisia.com]</strong>
          </p>
        </ul>

        <br></br>
        <br></br>

        <h3>9.Cookies and Tracking </h3>
        <p>We use cookies for:</p>
        <ul>
          <li>Session management </li>
          <li>Traffic analysis (e.g., Google Analytics) </li>
          <li>Remembering preferences </li>
          <p>You can control cookie preferences through your browser. </p>
        </ul>

        <br></br>
        <br></br>

        <h3>10. Third-Party Links</h3>
        <p>
          Our platform may contain links to other websites or integrations
          (e.g., YouTube, Stripe). We are not responsible for the privacy
          practices of those sites. We recommend reviewing their privacy
          policies.{" "}
        </p>

        <br></br>
        <br></br>

        <h3>11.Children's Privacy</h3>
        <p>
          Katalisia is not intended for users under 13 years old (or under 16 in
          certain jurisdictions). We do not knowingly collect personal data from
          children.{" "}
        </p>

        <br></br>
        <br></br>

        <h3>12.Updates to This Policy</h3>
        <p>
          We may update this Privacy Policy from time to time. Changes will be
          posted here with the new effective date. Continued use of the service
          means you accept the updated terms.{" "}
        </p>

        <br></br>
        <br></br>

        <h3>13.Contact Us</h3>
        <p>For questions or concerns, contact: </p>
        <p>
          <strong>Data Protection Officer (DPO):</strong>
        </p>
        <p>
          Email: <strong>rohan@katalisia.com</strong>
        </p>

        <div className="button-container">
          <Link to="/">
            <button className="privacy-button">OK</button>
          </Link>
        </div>
      </div>
    </div>
  );
};
